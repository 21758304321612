@import url(https://fonts.googleapis.com/css?family=Oswald);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Stylesheet für globale Styles,
Alles was mehrfach verwendung findet, wird hier definiert. 
*/

html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 14px;
  background: #eee;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
}
.App {
  min-height: 100vh;
  background-color: #eee;
}
/* HELPER */

body * {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

/* Typografie */

h1 {
  margin-top: 0px;
  font-size: 28px;
  line-height: 32px;
  font-family: "Oswald", sans-serif;
}
h2,
h3,
h4 {
  font-family: "Oswald", sans-serif;
}

.oswald {
  font-family: "Oswald", sans-serif;
}

/* NAVIGATION, Tabbar oben */

.switch {
  border: 2px solid #000;
  display: inline-block;
  border-radius: 3px;
  font-family: "Oswald";
  margin-top: 16px;
  font-size: 12px;
  text-transform: uppercase;
}

.switch div {
  margin: 0 auto;
  display: inline-block;
  padding: 5px 15px;
  text-decoration: none;
  color: #000;
}

.switch div.active {
  background-color: #000;
  color: #fff;
  display: inline-block;
}

/* AKTIVE MENÜKLASSE */

.is-active {
  opacity: 0.5;
}

/* ANIMATIONEN */

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* CONTENT */

#contentwrapper {
  background-color: #eee;
  min-height: 100vh;
  padding-top: 48px;
  padding-bottom: 48px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  #contentwrapper {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .ril__toolbar {
    top: 48px;
  }
}
/* ALLGEMEINES MARGIN */

.p8 {
  padding: 8px;
}

.p16 {
  padding: 16px;
}
.p32 {
  padding: 32px;
}

/* ALLGEMEINES PADDING */

.m8 {
  margin: 8px;
}

.m16 {
  margin: 16px;
}

.m32 {
  margin: 32px;
}

/* CARD ELEMENT */

.card {
  display: block;
  text-align: left;
  overflow: hidden;
  background: #fff;
  box-sizing: border-box;
  border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  line-height: 150%;
}

a.card {
  text-decoration: none;
  color: #333;
}
.card a {
  text-decoration: none;
  color: #333;
}

.highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 150px;
  width: 100%;
  margin: 8px;
  flex-direction: column;
}

.highlight img {
  height: 50%;
  width: auto;
}

/* BUTTONS */

.btn {
  padding: 8px 16px;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  display: inline-block;
  color: #666;
  font-family: "Oswald";
  cursor: pointer;
}
.btn-red {
  background-color: darkred;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  color: #fff;
}
.btn-red:active {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}

.btn-white-border {
  border: 1px solid #fff;
  color: #fff;
}
.btn-white-border:active {
  border: 1px solid #fff;
  background-color: #fff;
  color: #333;
}

.btn-white-fill {
  border: 1px solid #fff;
  background-color: #fff;
  color: #000;
}

.btn-grey-border {
  border: 1px solid #333;
  color: #333;
}
.btn-full {
  display: block;
  text-align: center;
}

/* GAMEDAY */

.gameday {
  font-family: "Oswald";
  text-decoration: none;
}

.gameday .gameday__head {
  background-color: #ccc;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  text-transform: uppercase;
}

.gameday__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 8px;
}

.gameday__teamlogo {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.gameday__score {
  display: inline-block;
  font-size: 24px;
  padding: 10px;
}

.gameday .gameday__head.live {
  color: #fff;
  background-color: darkred;
}

/*TABELLE */

.c50 {
  width: 50%;
  float: left;
}
.c5 {
  float: left;
  width: 5%;
}
.c15 {
  width: 15%;
  float: right;
}

.clear {
  clear: both;
}
.row {
  display: block;
  border-bottom: 1px solid #eee;
}

.row:acive {
  background-color: #eee;
}

/*AUSRICHTUNG*/

.alignCenter {
  text-align: center;
}
.alignLeft {
  text-align: left;
}
.alighRight {
  text-align: right;
}

/* FORMS */

input,
select {
  border-radius: 3px;
  border: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  box-sizing: border-box;
  padding: 14px;
  width: 100%;
  font-size: 14px;
  background-color: #fff;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  padding-left: 55px;
}

.formcontrol {
  margin-bottom: 16px;
  position: relative;
}

.formcontrol.flex label {
  margin-top: 8px;
  width: 50%;
  text-align: left;
}
.formcontrol.flex select {
  margin-top: 8px;
}

.formcontrol.flex label:nth-child(1) {
  margin-right: 8px;
}
.formcontrol.flex label:nth-child(2) {
  margin-left: 8px;
}

.formcontrol__icon {
  left: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-size: contain;
  position: absolute;
}

.formcontrol__icon {
  left: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  background-size: contain;
  position: absolute;
}

.error {
  color: darkred;
}

.success {
  color: green;
}

/*SCANNER */

.result {
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #fff;
  height: calc(100vh - 100vw);
  background-color: #333;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  text-align: center;
}
.result img {
  width: 50px;
  height: 50px;
}

.error.result {
  background-color: darkred;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.success.result {
  background-color: green;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.scanning section section > div:first-child {
  box-shadow: green 0px 0px 0px 5px inset !important;
}

.back {
  position: absolute;
  background-size: contain;
  z-index: 1000000;
  top: 10px;
  left: 10px;
  height: 20px;
  width: 20px;
  background-image: url(/static/media/back.184e2b4e.svg);
}

